import { useEffect ,useState } from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom"
import {shortDirection} from '../../functions/functions_property'
import {get_shortDescription, getGroup_CustomTag,group_Unities} from '../../functions/functions_dev'
import circleBg from '../../assets/images/elipse_bg_emprendimiento.svg';

const Data = () => {
    // Store redux 
    const settings = useSelector(store => store.settings)
    const { data } = useSelector((store) => store.developments.development);
    const unities = useSelector((store) => store.developments.units_development).data;
    const [group,setGroup] = useState()
    const [groupAux,setGroupAux] = useState()
    const [load,setLoad] = useState(false)
    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)
    const [finance, setFinance] = useState("noTags")
    const updateFinance = (value) => {
        setFilters(0)
        if (value === finance) { 
            return setFinance("noTags") 
        }
        return setFinance(value)
    }

    useEffect(() => {
        if(unities){
            setGroupAux(group_Unities(unities.objects,filters,"customTags,Financiación"))
            setGroup(group_Unities(unities.objects,filters,finance))
            setLoad(true)
        }
    },[unities,filters,finance])


    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    const validateGroup = () => {
        if(!group){return false}
        if(group.length===0){return false}
        console.log(group)
        return true
    }

    // Filter state to show buttons
    const [buttonsFilters, setButtonsFilters] = useState([])

    // Use Effect to set buttons array only
    useEffect(() => {
        // if unities and group are loadead
        if(unities && group) {
            // and the state array is 0
            if(buttonsFilters.length === 0)
                setButtonsFilters(groupFilter(group))
        }
    },[unities, group])

    // Helper function to filter
    const groupFilter = (group) => {
        // first take the unities
        let filteredUnities = [];
        for (let i = 0; i < group.length; i++) {
            filteredUnities.push(...group[i].unities);
        }
        // and then take rooms
        let filteredUnitiesByRooms = [];
        for (let fu of filteredUnities) {
            if (!filteredUnitiesByRooms.includes(fu.rooms))
                filteredUnitiesByRooms.push(fu.rooms);
        }
        return filteredUnitiesByRooms;
    }

    // Function to do dynamic buttons
    // Room amount btns need to start empty when the finance filter change
    const dynamicBtn = (btns) => {
        let btnFiltered = []
        if (finance === "noTags") {
            return btns
        } else {
            let unitiesFiltered;
            if (finance === "notCustomTags") {
                unitiesFiltered = unities.objects.filter(u => !u.custom_tags.find(c => c.id === 24675))
            } else {
                unitiesFiltered = unities.objects.filter(u => u.custom_tags.find(c => c.id === 24675))
            }
            for (let i = 0; i < btns.length; i++) {
                const ambiente = btns[i];
                for (let x = 0; x < unitiesFiltered.length; x++) {
                    if (unitiesFiltered[x].room_amount === ambiente) {
                        if(!btnFiltered.find(btn => btn === ambiente)) {
                            btnFiltered.push(ambiente);
                        }
                    }
                }
            }
            return btnFiltered
        }
    }    

  // Render
  return unities && group ? (
    <>
        <section className={"div_unidades " + (unities.meta.total_count > 0 ? '' : 'd-none')}>
            <div className="container-fluid">
                <h2 className="fs-3 text-bold text-center text-uppercase">Unidades</h2>
            </div>
            <div className="container-fluid fluid-2">
                <h3 className="ps-3 col-lg-12 font-1 fs-5 text-regular mt-3 mt-lg-2 mb-4 mb-lg-0 ">Filtrar por:</h3>
                <section className={"mt-lg-3 pt-lg-0 flex-row justify-content-center unities position-relative w-100 overflow-hidden" + (unities.meta.total_count === 0 || group.length === 0 && filters === 0 ? ' d-none ' : '')} id="unidades">
                    <div className="div_financiacion text-center mb-0 col-lg-10">
                        <div className="div_tags row mt-2 d-flex flex-wrap justify-content-start">
                            <div className={"p-2 mt-1  fs-8 text-semibold " + ((groupAux && groupAux?.length > 0) ? '' : 'disabled') + (finance === "customTags,Financiación" ? 'active' : '')} onClick={() => (groupAux && groupAux?.length > 0) ? updateFinance("customTags,Financiación") : ''
                                 }> 
                                <div className={"tag-filter p-2 " + ((groupAux && groupAux?.length > 0) ? '' : 'disabled') + (finance === "customTags,Financiación" ? 'active' : '')}>
                                    Con financiamiento
                                </div>
                            </div>
                            <div className={"p-2 mt-1  fs-8 text-semibold " + (finance === "notCustomTags" ? 'active' : '')} onClick={() => updateFinance("notCustomTags")}> 
                                <div className={"tag-filter p-2 " + (finance === "notCustomTags" ? 'active' : '')}>
                                    Sin financiamiento
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_ambientes text-center mb-3 col-lg-10" >
                        <div className="div_tags row mt-0 mb-2 d-flex flex-wrap justify-content-start">
                            { dynamicBtn(buttonsFilters).sort((a,b)=>a-b).map((item, index) => 
                                <div key={index} className={"p-2 mt-1  fs-8 text-semibold " + (filters === item ? ' active ' : '')} onClick={() => updateFilter(item)}> 
                                    <div className={"tag-filter p-2" + (filters === item ? ' active ' : '')}>
                                        { item === 1 ? 'Monoambiente' : (item <= 5 ? `${item} ambientes` : '+') }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="div_pisos col-lg-12 m-auto ">
                        {
                            group && load ? 
                            group.map((item,index) => (
                                    <div className="unity collapsed" data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                        <div className="head-row row pe-0 pe-lg-2">
                                            <div className="col-2 px-0 px-md-2 mt-1">
                                                <span className="unity-font fs-5 text-semibold">{item.floor}</span>
                                            </div>
                                            <div className="col-lg-1 col-1 d-none d-lg-block text-center  px-0 px-md-2 mt-1" >
                                                <span className="unity-font sub-title fs-5 text-semibold text-center" >Amb.</span>
                                            </div>
                                            <div className="col-2 d-none d-lg-block text-center  px-0 px-md-2 mt-1" >
                                                <span className="unity-font sub-title fs-5 text-semibold text-center" >Sup. cubierta </span>
                                            </div>
                                            <div className="col-2 text-center  px-0 px-md-2 mt-1">
                                                <span className="unity-font sub-title fs-5 text-semibold text-center d-none d-md-inline d-lg-inline d-xl-inline" >Sup. Total </span>
                                                <span className="unity-font sub-title fs-5 text-semibold text-center d-xs-inline d-sm-inline d-md-none d-lg-none" >Sup.</span>
                                            </div>
                                            <div className="col-2 text-center  px-0 px-md-2 mt-1" >
                                                <span className="unity-font sub-title fs-5 text-semibold text-center" >Precio </span>
                                            </div>
                                            <div className="col-2 text-center  px-0 px-md-2 mt-1" >
                                                <span className="unity-font sub-title fs-5 text-semibold text-center d-none d-md-inline d-lg-inline d-xl-inline" >Financiación </span>
                                                <span className="unity-font sub-title fs-5 text-semibold text-center d-xs-inline d-sm-inline d-md-none d-lg-none" >Financ. </span>
                                            </div>
                                            <div className={'col-1 col_arrow  px-0 px-md-2 mt-1'} style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                {/* <i className={'icon-arrow-light'  + (isArrowClicked ? ' active_arrow' : '')}></i> */}
                                                <i className={'icon-arrow-light'}></i>
                                            </div>
                                        </div>
                                        <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                                {/* {item.unities.sort(function(a, b){return a.address.split(" ")[1] - b.address.split(" ")[1]}).map((element,index) => ( // NO ANDA??                            (element.status === 2 ? */}
                                                {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
                                                    (element.status === 2 ?
                                                    <>
                                                        <a href={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)} target="_blank">
                                                            <div className={"unity-row row pe-0 pe-lg-2 " + (element.status !== 2 ? 'disabled' : '')} >
                                                                <div className="col-2 px-0 px-md-2 mt-1">
                                                                    <span className={" unity-font unidad fs-5 text-bold text-center"}>{element.address}</span>
                                                                </div>
                                                                <div className="col-1 d-none d-lg-block px-0 px-md-2 mt-1" style={{textAlign:'center'}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-2 d-none d-lg-block px-0 px-md-2 mt-1" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-2 px-0 px-md-2 mt-1" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-2 px-0 px-md-2 mt-1" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" > {element.currency} {element.price === 'A Consultar' ? element.price :  new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                                </div>
                                                                <div className="col-2 px-0 px-md-2 mt-1" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" > 
                                                                        {element?.customTags?.find(c => c.id === 24675) ? "SI" : "NO"} 
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 text-end align-self-center px-0 px-md-2 mt-1"><i className="icon-arrow arrow-direct"></i></div>
                                                            </div>
                                                        </a>
                                                    </>
                                                :
                                                    <>
                                                        <div to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
                                                            <div className={"unity-row row disabled"} >
                                                            <div className="col-lg-2 col-3">
                                                                    <span className={" unity-font fs-5 text-bold text-center"}>{element.address}</span>
                                                                </div>
                                                                <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" >{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font fs-5 text-regular text-center" > {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                                </div>
                                                                <div className="col-1"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                ))
                            : //Están cargando los desarrollos
                            <>
                                <div class="loader relative">
                                    <div class="page-lines">
                                        <div class="container">
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                            <div class="line"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="loader-brand">
                                        <div class="sk-folding-cube">
                                        <div class="sk-cube1 sk-cube"></div>
                                        <div class="sk-cube2 sk-cube"></div>
                                        <div class="sk-cube4 sk-cube"></div>
                                        <div class="sk-cube3 sk-cube"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                        <p className="fs-11 disabled-unities  text-center text-white" style={{margin: "3rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                    </div>
                    <div className="col-10 m-auto div_unidad_inactiva text-white">
                        {/* <p className="fs-11 disabled-unities  text-start" >* Las unidades inactivas  no están disponibles.</p> */}
                    </div>
                </section>
                
            </div>
        </section>
        

    </>
  ):''
};

export default Data;

                            